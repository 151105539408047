import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {} from "../../helpers/Firebase";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";

import { adminRoot, currentUser } from "../../constants/defaultValues";
import { setCurrentUser } from "../../helpers/Utils";

export default function* rootSaga() {
  yield all([]);
}
