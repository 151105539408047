import {
  makeObservable,
  makeAutoObservable,
  observable,
  action,
  computed,
} from "mobx";
import { log } from "../../utils/logger";
export class AppStore {
  user = {};
  token = "";
  application = {};

  guser = {};
  fuser = {};
  deviceDetails = {};
  networkDetails = {};
  count = 0;

  processRes = {};
  onBoardingData = {};
  appVersionId = "";
  serverUrl = "";

  configData = [];
  statusAPIresponse = {};

  isAuth = false;
  loginInitRoute = "OnboardingScreen";

  processStatus = {};

  tandtTask = {};
  s3Task = {};
  cleanupTask = {};
  processStatus = {};
  processId = {};
  transcriptJson = {};

  initConfig = {};
  webConfig = {};

  dataStore = {};
  userStats = {};
  vlt = {};
  monthlyLimit = 0;
  sourceSubsList = {};
  targetSubsList = {};

  constructor() {
    makeAutoObservable(this);
  }

  setSourceSubsList(data) {
    localStorage.setItem("sourceSubsList", JSON.stringify(data));
    this.sourceSubsList = data;
  }

  setTargetSubsList(data) {
    localStorage.setItem("targetSubsList", JSON.stringify(data));
    this.targetSubsList = data;
  }

  get getSourceSubList() {
    this.sourceSubsList =
      Object.keys(this.sourceSubsList).length > 0
        ? this.sourceSubsList
        : JSON.parse(localStorage.getItem("sourceSubsList"));
    return this.sourceSubsList;
  }

  get getTargetSubList() {
    this.targetSubsList =
      Object.keys(this.targetSubsList).length > 0
        ? this.targetSubsList
        : JSON.parse(localStorage.getItem("targetSubsList"));
    return this.targetSubsList;
  }

  setDataStore(data) {
    localStorage.setItem("dataStore", JSON.stringify(data));
    this.dataStore = data;
  }

  setUserStats(stats) {
    localStorage.setItem("userStats", JSON.stringify(stats));
    this.userStats = stats;
  }

  setMonthlyLimit(limit) {
    localStorage.setItem("vitra_monthlylimit", limit);
    this.monthlyLimit = limit;
  }

  get getMonthlyLimit() {
    return this.monthlyLimit
      ? this.monthlyLimit
      : localStorage.getItem("vitra_monthlylimit");
  }

  get getDataStore() {
    this.dataStore =
      Object.keys(this.dataStore).length > 0
        ? this.dataStore
        : JSON.parse(localStorage.getItem("dataStore"));
    return this.dataStore;
  }

  get getUserStats() {
    try {
      this.userStats =
        Object.keys(this.userStats).length > 0
          ? this.userStats
          : JSON.parse(localStorage.getItem("userStats"));
    } catch (error) {
      log("ERRIR", error);
      return {};
    }
    return this.userStats;
  }

  setInitConfig(config) {
    localStorage.setItem("initConfig", JSON.stringify(config));
    this.initConfig = config;
  }

  setWebConfig(config) {
    localStorage.setItem("webConfig", JSON.stringify(config));
    this.webConfig = config;
  }

  get getInitConfig() {
    this.initConfig =
      Object.keys(this.initConfig).length > 0
        ? this.initConfig
        : JSON.parse(localStorage.getItem("initConfig"));
    return this.initConfig;
  }

  get getWebConfig() {
    this.webConfig =
      Object.keys(this.webConfig).length > 0
        ? this.webConfig
        : JSON.parse(localStorage.getItem("webConfig"));
    return this.webConfig;
  }

  get getTranscriptJson() {
    this.transcriptJson =
      Object.keys(this.transcriptJson).length > 0
        ? this.transcriptJson
        : JSON.parse(localStorage.getItem("transcriptJson"));
    return this.transcriptJson;
  }

  setTranscript(trans) {
    localStorage.setItem("transcriptJson", JSON.stringify(trans));
    this.transcriptJson = trans;
  }

  get getTranscriptJson() {
    this.transcriptJson = this.transcriptJson
      ? this.transcriptJson
      : JSON.parse(localStorage.getItem("transcriptJson"));
    return this.transcriptJson;
  }

  setTandTask(task) {
    localStorage.setItem("tantTask", JSON.stringify(task));
    this.tandtTask = task;
  }

  setS3Task(task) {
    localStorage.setItem("s3Task", JSON.stringify(task));
    this.s3Task = task;
  }

  setCleanupTask(task) {
    localStorage.setItem("cleanupTask", JSON.stringify(task));
    this.cleanupTask = task;
  }

  setProcessStatus(task) {
    localStorage.setItem("processStatus", JSON.stringify(task));
    this.processStatus = task;
  }

  get getTantTask() {
    this.tandtTask = this.tandtTask.id
      ? this.tandtTask
      : JSON.parse(localStorage.getItem("tandtTask"));
    return this.tandtTask;
  }

  get getS3Task() {
    this.s3Task = this.s3Task.id
      ? this.s3Task
      : JSON.parse(localStorage.getItem("s3Task"));
    return this.s3Task;
  }

  get getCleanupTask() {
    this.cleanupTask = this.cleanupTask.id
      ? this.cleanupTask
      : JSON.parse(localStorage.getItem("cleanupTask"));
    return this.cleanupTask;
  }

  get getProcessStatus() {
    this.processStatus = this.processStatus
      ? this.processStatus
      : JSON.parse(localStorage.getItem("processStatus"));
    return this.processStatus;
  }

  setIsAuth(auth) {
    this.isAuth = auth;
  }

  setDeviceDetails(deviceInfo) {
    this.deviceDetails = deviceInfo;
    log(deviceInfo);
  }

  setNetworkDetails(networkInfo) {
    this.networkDetails = networkInfo;
    log(networkInfo);
  }

  setInitLoginRoute(screen) {
    this.loginInitRoute = screen;
  }

  setToken(token) {
    log("[Token]Set.token:", token);
    localStorage.setItem("vitra-token", token);
    this.token = token;
    log("[Token]Set.this.token:", this.token);
  }

  get getToken() {
    log("[Token]Get This.token:", this.token);
    log("[Token]Get local storage:", localStorage.getItem("vitra-token"));
    return this.token ? this.token : localStorage.getItem("vitra-token");
  }

  setApplication(application) {
    localStorage.setItem("vitra-application", JSON.stringify(application));
    this.application = application;
  }

  get getApplication() {
    this.application = this.application.id
      ? this.application
      : JSON.parse(localStorage.getItem("vitra-application"));
    return this.application;
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.user = user;
  }

  setAdmin(admin) {
    this.user.admin = admin;
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  get getUser() {
    this.user = this.user.id
      ? this.user
      : JSON.parse(localStorage.getItem("user"));
    return this.user;
  }

  setgUser(user) {
    this.guser = user;
  }

  setfUser(user) {
    this.fuser = user;
  }
}
