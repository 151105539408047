/* eslint-disable dot-notation */
import axios from "axios";
import { log } from "../../src/utils/logger";
//import Constants from "expo-constants";

const axiosAuth = (store) => {
    try {
        //Constants.manifest.extra.baseurl
        //log("BASE URL:" );
        // log('ENV:', Config.ENV);
        // log('CONFIG_KEY:', Config.CONFIG_KEY);

        let REACT_APP_BASE_URL;
        switch (process.env.REACT_APP_VITRAPR_ENVIRONMENT_MODE) {
            case "DEV":
                REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
                break;
            case "STAGING":
                REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL_STAGING;
                break;
            case "PROD":
                REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
                break;
            default:
                REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
                break;
        }
        const instance = axios.create({
            // baseURL: 'http://27.109.14.75:85/Mindspark/Framework/Mindspark/CommonLogin',
            baseURL: REACT_APP_BASE_URL,
        });
        log("\n[Token] Store token:", store.appStore.getToken);
        log("[Token] Local Storeage:", localStorage.getItem("token"));

        instance.defaults.headers.common["Content-Type"] = "application/json";
        instance.defaults.headers.common["Authorization"] = store.appStore.getToken;

        instance.interceptors.request.use(
            (request) => {
                log(`\n\nRequest Log Data>>>>${JSON.stringify(request)}`);
                return request;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                try {
                    log(`Axios Response Error0091>>>${JSON.stringify(error.response.data)}`);
                    log("API REPOSE STATUS:", error.response.status);
                    if (error.response.status === 401) {
                        return Promise.reject({
                            message: error.response.data,
                            status: error.response.status,
                        });
                    }
                    if (error.response.status === 400) {
                        return Promise.reject(error.response.data);
                    }
                    if (error.response.status === 500) {
                        return Promise.reject(error.response.data);
                    } else {
                        return Promise.reject(error.response.data);
                    }
                } catch (err) {
                    return Promise.resolve(error);
                }
            }
        );

        return instance;
    } catch (err) {
        log("Error in Axios Auth", err);
    }
};

export default axiosAuth;
