import {
    defaultDirection,
    defaultLocale,
    defaultColor,
    localeOptions,
    themeColorStorageKey,
    themeRadiusStorageKey,
} from "../constants/defaultValues";
import { log } from "../utils/logger";

export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
        const A = a[key];
        const B = b[key];
        if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
            return 1;
        }
        return -1;
    });
    return array;
};

export const getDatesWithinRange = (startDate, endDate) => {
    let listDate = [];
    let start = new Date(startDate);
    start = new Date(start.setHours(0, 0, 0, 0));
    let end = new Date(endDate);
    end = new Date(end.setHours(23, 59, 59, 59));
    let pointer = new Date(start);
    while (pointer.getTime() <= end.getTime()) {
        let toPush = new Date(pointer);
        listDate.push(`${toPush.getDate()}-${toPush.getMonth() + 1}-${toPush.getFullYear()}`);
        pointer = new Date(pointer.getTime() + 24 * 60 * 60 * 1000);
    }
    return listDate;
};

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!

    const yyyy = today.getFullYear();
    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }
    return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
    let direction = defaultDirection;

    try {
        if (localStorage.getItem("direction")) {
            const localValue = localStorage.getItem("direction");
            if (localValue === "rtl" || localValue === "ltr") {
                direction = localValue;
            }
        }
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
        direction = defaultDirection;
    }
    return {
        direction,
        isRtl: direction === "rtl",
    };
};
export const setDirection = (localValue) => {
    let direction = "ltr";
    if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
    }
    try {
        localStorage.setItem("direction", direction);
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : setDirection -> error", error);
    }
};

export const getCurrentColor = () => {
    let currentColor = defaultColor;
    try {
        if (localStorage.getItem(themeColorStorageKey)) {
            currentColor = localStorage.getItem(themeColorStorageKey);
        }
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
        currentColor = defaultColor;
    }
    return currentColor;
};

export const setCurrentColor = (color) => {
    try {
        localStorage.setItem(themeColorStorageKey, color);
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
    }
};

export const getCurrentRadius = () => {
    let currentRadius = "rounded";
    try {
        if (localStorage.getItem(themeRadiusStorageKey)) {
            currentRadius = localStorage.getItem(themeRadiusStorageKey);
        }
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error);
        currentRadius = "rounded";
    }
    return currentRadius;
};
export const setCurrentRadius = (radius) => {
    try {
        localStorage.setItem(themeRadiusStorageKey, radius);
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error);
    }
};

export const getCurrentLanguage = () => {
    let language = defaultLocale;
    try {
        language =
            localStorage.getItem("currentLanguage") &&
            localeOptions.filter((x) => x.id === localStorage.getItem("currentLanguage")).length > 0
                ? localStorage.getItem("currentLanguage")
                : defaultLocale;
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error);
        language = defaultLocale;
    }
    return language;
};
export const setCurrentLanguage = (locale) => {
    try {
        localStorage.setItem("currentLanguage", locale);
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error);
    }
};

export const getCurrentUser = () => {
    let user = null;
    try {
        user =
            localStorage.getItem("gogo_current_user") != null
                ? JSON.parse(localStorage.getItem("gogo_current_user"))
                : null;
    } catch (error) {
        log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
        user = null;
    }
    return user;
};

export const setCurrentUser = (user) => {
    try {
        if (user) {
            log("USER:", user);
            localStorage.setItem("gogo_current_user", JSON.stringify(user));
        } else {
            localStorage.removeItem("gogo_current_user");
        }
    } catch (error) {
        log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
    }
};

export const createUUID = () => {
    const pattern = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    return pattern.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
