export const ApiEndPoint = {
    SIGNUP: "/user/signup",
    LOGIN: "/user/login",
    VERIFY_OTP: "/user/verify-otp",
    CREATE_APPLICATION: "/application",
    GET_APPLICATION: "/application",
    DATASTORE: "/datastore",
    UPDATE_USER: "/user",
    UPDATE_SETTINGS: "/user/settings",
    PROJECT: "/project",
    CUSTOMER: "/customer",
    EVENTS: "/event",
    CUSTOMER_DATASTORE: "/datastore?dskey=CUSTOMER_CATEGORIES",
    STATUS_DATASTORE: "/datastore?dskey=PROJECT_TASK_STATUSES",
    LANGUAGES_DATASTORE: "/datastore?dskey=LANGUAGES",
    ONBOARDING_DATASTORE: "/datastore?dskey=ONBOARDING_VIDEOS",
    ASSESSMENT_DATASTORE: "/datastore?dskey=ASSIGNMENT_TASKS",
    ONBOARDING_ADMIN: "/onboarding-admin",
    USER: "/user",
    ALL_USER: "/user/all",
    GET_INCENTIVE: "/incentive/user",
    TASK: "/task",
    TASKAUDIT: "/taskaudit",
    TAP_TAP_TEST_ASSIGNMENTS: "/tap-tap-test/assignments",
    TAP_TAP_TEST_ASSIGNMENTS_ADMIN: "/tap-tap-test/admin-assignments",
    TAP_TAP_TEST_ASSIGNMENTS_ADMIN_META_INFO: "/tap-tap-test/admin-assignment-informations",
    TAP_TAP_TEST_RESERVED_LANGUAGE: "/tap-tap-test/reserved-language",
    TAP_TAP_TEST_RESULT: "/tap-tap-test/result",
    TAP_TAP_TEST_ANALYTICS: "/tap-tap-test/analytics",
    TAP_TAP_TEST_LANG_OPTIONS: "/tap-tap-test/langOption",
    LOGGER: "/logger",
    GETCHANNELIDS: "/project/getChannelIds",
    SLACK_ONE_TO_ONE_CHANNEL: "/slack-integration/create-channel-for-one-to-one-communication",
    SLACK_MESSAGE_TO_CHANNEL: "/slack-integration/post-message-to-mutliple-one-to-one-channels",
    POST_MESSAGE: "/slack-integration/post-message-to-a-channel",
    UPDATE_CHANNEL_MEMBERS: "/slack-integration/update-channel-members",
    NOTIFYTASK: "/task/notify",
    GETUSERSFORUSERIDS: "/task/getUsers",
    GET_TASK_DATE_RANGE_BY_PROJID: "/task/get-date-range-by-projectid",
    TASK_ANALYTICS: "/task/analytics",
    TASK_ANALYTICS_PROCESS_TYPE: "/task/analytics-process-type",
    TOOLS: "/tools",
    NOTES: "/notes",
    DATA_RANKING_TEXT_TO_TEXT: "/data-ranking/text-to-text",
    DATA_RANKING_SPEECH_TO_TEXT: "/data-ranking/speech-to-text",
    DATA_TRAINING_TEXT_TO_TEXT: "/data-training/text-to-text",
    DATA_TRAINING_SPEECH_TO_TEXT: "/data-training/speech-to-text",
    ANALYTICS: "/analytics",
};
