import {API} from "../api";
import {ApiEndPoint} from "../constants";
import {AppStore, UIStore} from "../mobx/stores";

export const log = (info1 = "", info2 = "") => {
  if (window.location.host.includes("localhost")) {
    console.log(info1, info2);
  }
};

export const logApi = async (error, stack, options={}) => {
  let req={
    body: {
      message: error.message,
      trace: stack,
      options: options,
    },
    store:{
      appStore: new AppStore(),
      uiStore: new UIStore(),
    }
  }
  try {
    const res = await API(ApiEndPoint.LOGGER, req, "POST");
    log('res>>>',res);
  } catch(err){
    log(err);
  }
}