import { observable, action, computed, makeAutoObservable } from "mobx";

export class UIStore {
  loader = false;
  isNetConnected = true;
  theme = "light";
  color = {};

  infoPopupVisible = false;
  feedbackPopupVisible = false;
  infoMessage = "";

  constructor() {
    makeAutoObservable(this);
  }

  showFeedbackPopup(flag) {
    this.feedbackPopupVisible = flag;
  }

  setLoader(flag) {
    this.loader = flag;
  }

  setIsNetConnected(flag) {
    this.isNetConnected = flag;
  }

  setTheme(theme) {
    this.theme = theme;
  }

  showInfo(message, duration = 2000, callback) {
    this.infoMessage = message;
    this.infoPopupVisible = true;
    var that = this;
    setTimeout(() => {
      that.infoPopupVisible = false;
      if (callback) {
        callback();
      }
    }, duration);
  }
}
