import axiosAuth from "../axios/axiosAuth";
import { log } from "../utils/logger";

const API = async (endPoint, req, type = "GET") => {
  try {
    const request = {
      url: endPoint,
      body: req.body,
    };
    console.log(endPoint);
    const axios = axiosAuth(req.store);
    log(`\n\n${endPoint} API Request: ` + JSON.stringify(req.body));
    console.log(request.url);
    switch (type) {
      case "POST":
        return axios.post(request.url, req.body);
      case "GET":
        return axios.get(request.url, req.body);
      case "PUT":
        return axios.put(request.url, req.body);
      case "DELETE":
        return axios.delete(request.url, {
          data: req.body
        });

      default:
        return { success: false, data: { message: "Unknown API request" } };
    }
  } catch (error) {
    log("ERROR:", error);
    // TODO: Send the error as an Event
  }
};

export default API;
