import React, { useEffect } from "react";

const AppError = ({ error, resetErrorBoundary }) => {
    useEffect(() => {
        console.log("Error from error page:", error);
        document.body.classList.add("background");
        document.body.classList.add("no-footer");
        return () => {
            document.body.classList.remove("background");
            document.body.classList.remove("no-footer");
        };
    }, []);
    useEffect(() => {
        if (error.status === 401) {
            // window.location.replace(window.location.hostname + "/user/login");
            window.location.pathname = "/user/login";
        }
    }, [error]);

    return (
        <>
            <div className="fixed-background" />
            <main>
                <div className="container h-100">
                    <div className="row justify-content-center h-100">
                        <div className="col-12 col-md-6 mx-auto my-auto text-center text-dark">
                            <div className="card uth-card py-5">
                                <div className="position-relative image-side">
                                    <p className="text-dark h2">Something Went Wrong</p>
                                    <p className="text-dark mb-0">Error Reported To Admin</p>
                                </div>
                                <div className="form-side mt-4">
                                    {/* <div className="card-title mb-4 pt-3">
                    Error: {error.message}
                  </div>
                  <p className="mb-0 text-muted text-small mb-0">Error Code</p>
                  <p className="display-1 font-weight-bold mb-5">500</p> */}
                                    <button
                                        className="btn btn-primary"
                                        onClick={resetErrorBoundary}
                                    >
                                        Try again
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default AppError;
